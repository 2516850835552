import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import './styles/home.css';

// Import page components
import DeskList from './pages/DeskList';
import DeskDetail from './pages/DeskDetail';
import FileList from './pages/FileList';
import FileDetail from './pages/FileDetail';
import DocumentList from './pages/DocumentList';
import DocumentDetail from './pages/DocumentDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />

          {/* Desk Routes */}
          <Route path="/desks" element={<DeskList />} />
          <Route path="/desks/:id" element={<DeskDetail />} />

          {/* File Routes */}
          <Route path="/files" element={<FileList />} />
          <Route path="/files/:id" element={<FileDetail />} />

          {/* Document Routes */}
          <Route path="/documents" element={<DocumentList />} />
          <Route path="/documents/:id" element={<DocumentDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;