import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DeskList() {
    const [desk, setDesk] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchDesks = async () => {
            try {
                const response = await fetch('http://localhost:3001/api/desks');

                if (!response.ok) {
                    throw new Error('Failed to fetch desks');
                }

                const data = await response.json();
                setDesk(data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchDesks();
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
    <div>
      <h1>Desks</h1>
      {desk.map(desk => (
        <div key={desk.id}>
          <Link to={`/desks/${desk.id}`}>
            {desk.name}
          </Link>
    
    <div className="navigation-links">
                <Link to="/">Back to Home</Link>
            </div>
        </div>
      ))}
    </div>
  );
}

export default DeskList;