import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>Welcome to Document Management System</h1>
      <div className="navigation-grid">
        <Link to="/desks" className="nav-card desks">
          <h2>Desks</h2>
          <p>View and manage your desks</p>
        </Link>
        
        <Link to="/files" className="nav-card files">
          <h2>Files</h2>
          <p>Browse and organize files</p>
        </Link>
        
        <Link to="/documents" className="nav-card documents">
          <h2>Documents</h2>
          <p>Access your important documents</p>
        </Link>
      </div>
    </div>
  );
}

export default Home;
