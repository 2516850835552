import React from 'react';
import { Link } from 'react-router-dom';
import documents from '../data/document.json'; // You'll need to create this JSON

function DocumentList() {
  return (
    <div>
      <h1>Documents</h1>
      {documents.map(doc => (
        <div key={doc.id}>
          <Link to={`/documents/${doc.id}`}>
            {doc.id} - {doc.name}
          </Link>
        </div>
      ))}
      <div>
        <Link to="/files">Go to Files</Link>
      </div>
    </div>
  );
}

export default DocumentList;
