import React from 'react';
import { Link } from 'react-router-dom';
import files from '../data/file.json';

function FileList() {
  return (
    <div>
      <h1>Files</h1>
      {files.map(file => (
        <div key={file.id}>
          <Link to={`/files/${file.id}`}>
            {file.name}
          </Link>
        </div>
      ))}
      <div>
        <Link to="/desks">Go to Desks</Link>
        <Link to="/documents">Go to Documents</Link>
      </div>
    </div>
  );
}

export default FileList; 