import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import files from '../data/file.json';
function DeskDetail() {
  const { id } = useParams();
  const [desk, setDesk] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeskDetails = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/desks');
        if (!response.ok) {
          throw new Error('Failed to fetch desk details');
        }
        const data = await response.json();
        const desk = data.find(d => d.id === id);
        setDesk(desk);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchDeskDetails();
  }, [id]) 

  if (isLoading) return <div>Loading desk details...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!desk) return <div>Desk not found</div>;

  return (
    <div>
      <h1>Desk Details</h1>
      <p>ID: {desk.id}</p>
      <p>Name: {desk.name}</p>
      <p>Description: {desk.description}</p>
      <p>Content: {desk.content}</p>
    </div>
  );
}

export default DeskDetail;
