import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import localFiles from '../data/file.json';
import localDocuments from '../data/document.json';
import '../styles/file.css';

function FileDetail() {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        // First, try to fetch from API
        const response = await fetch('http://localhost:3001/api/files');
        
        if (!response.ok) {
          // If API fetch fails, fall back to local data
          throw new Error('API fetch failed');
        }
        
        const apiData = await response.json();
        const fetchedFile = apiData.find(f => f.id === id);
        
        if (fetchedFile) {
          setFile(fetchedFile);
        } else {
          // If not found in API, try local data
          const localFile = localFiles.find(f => f.id === id);
          if (localFile) {
            setFile(localFile);
          } else {
            throw new Error('File not found');
          }
        }
        
        // Fetch associated documents
        const relatedDocuments = localDocuments.filter(doc => doc.fileId === id);
        setDocuments(relatedDocuments);
        
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching file details:', err);
        
        // Fallback to local data completely
        const localFile = localFiles.find(f => f.id === id);
        const relatedDocuments = localDocuments.filter(doc => doc.fileId === id);
        
        if (localFile) {
          setFile(localFile);
          setDocuments(relatedDocuments);
          setIsLoading(false);
        } else {
          setError(err.message);
          setIsLoading(false);
        }
      }
    };

    fetchFileDetails();
  }, [id]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!file) return <div>File not found</div>;

  return (
    <div className="file-detail-container">
      <h1>File Details</h1>
      <div className="file-info">
        <table> 
            <tr className="file-id-row">
                <td className="file-id-label">ID:</td>
                <td className="file-id-value">{file.id}</td>
            </tr>
            <tr className="file-name-row">
                <td className="file-name-label">Name:</td>
                <td className="file-name-value">{file.name}</td>
            </tr>
            <tr className="file-description-row">
                <td className="file-description-label">Description:</td>
                <td className="file-description-value">{file.description}</td>
            </tr>
            <tr className="file-details-row">
                <td className="file-details-label">Details:</td>
                <td className="file-details-value">{file.details}</td>
            </tr>  
            </table>
        </div>

      <div className="associated-documents">
        <h3>Linked Documents</h3>
        {documents.length > 0 ? (
          <ul>
            {documents.map(doc => (
              <li key={doc.id}>
                <p>{doc.name}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No documents associated with this file.</p>
        )}
      </div>

      <div className="navigation-links">
        <Link to="/files">Back to Files</Link>
        <Link to="/desks">Go to Desks</Link>
      </div>
    </div>
  );
}

export default FileDetail;