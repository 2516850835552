import React from 'react';
import { useParams, Link } from 'react-router-dom';
import documents from '../data/document.json';

function DocumentDetail() {
  const { id } = useParams();
  const document = documents.find(d => d.id === parseInt(id));

  if (!document) return <div>Document not found</div>;

  return (
    <div>
      <h1>Document Details</h1>
      <p>ID: {document.id}</p>
      <p>Name: {document.name}</p>
      <Link to="/documents">Back to Documents</Link>
      <Link to="/files">Go to Files</Link>
    </div>
  );
}

export default DocumentDetail;
